<template>
  <div class="standard-page">
    <!-- Show a full width info card -->
    <v-card
      class="mb-8"
      color="primary"
      flat
      dark
    >
      <v-card-title>
        <v-icon class="mr-3">
          lightbulb
        </v-icon>

        Coming Soon ...
      </v-card-title>

      <v-card-subtitle>
        We are still working on this feature, please check back later.
      </v-card-subtitle>
    </v-card>

    <!-- Header and Actions -->
    <div class="d-flex justify-space-between mb-8">
      <div>
        <!-- Select Input for Filter -->
        <v-select
          class="contain-select-width"
          label="Filter"
          outlined
          dense
          hide-details
          :items="filterOptions"
          v-model="selectedFilter"
        ></v-select>
      </div>

      <!-- Actions -->
      <div class="d-flex align-center">
        <!-- Create New Campaign Link -->
        <v-btn
          color="primary"
          depressed
          class="shadow--primary"
          :to="{ name: 'InfluencerOutreachCreate' }"
        >
          <v-icon left>add</v-icon>
          Create Campaign
        </v-btn>
      </div>
    </div>

    <!-- Data Table -->
    <template v-if="tableItems.length">
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        hide-default-footer
        disable-pagination
        class="elevation-1"
        :mobile-breakpoint="100"
      >
        <!-- Hide the checkbox to select all from header -->
        <template v-slot:header.data-table-select></template>

        <!-- The header for actions column -->
        <template v-slot:header.status>
          <span class="d-block text-center">
            Status
          </span>
        </template>

        <!-- The header for actions column -->
        <template v-slot:header.actions>
          <span class="d-block text-right">
            Actions
          </span>
        </template>

        <!-- For status column -->
        <template v-slot:item.status="{ item }">
          <div class="d-flex justify-end">
            <status-chip
              small
              :status="item.status"
            />
          </div>
        </template>

        <!-- For actions -->
        <template v-slot:item.actions="{ item }">
          <div class="d-flex justify-end">
            <v-btn
              small
              depressed
              class="mr-3"
              color="primary"
              @click="selectedItem = item; shouldShowDeleteDialog = true;"
            >
              Delete

              <v-icon right>
                delete
              </v-icon>
            </v-btn>

            <v-btn
              small
              depressed
              class="ml-3"
              color="primary"
              @click="$router.push({ name: 'InfluencerOutreachDetail', params: { uuid: item.uuid } })"
            >
              View

              <v-icon right>
                navigate_next
              </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>

    <!-- Fallback -->
    <template v-else-if="!isLoading">
      <div style="max-width: 360px" class="mx-auto">
        <lottie-animation
          :loop="false"
          :speed="0.5"
          file="5081-empty-box.json"
        />
      </div>


      <div class="d-flex justify-center">
        <v-btn
          v-if="selectedFilter === 'all'"
          text
          color="primary"
          :to="{ name: 'InfluencerOutreachCreate' }" class="no-decoration"
        >
          Create your first campaign!

          <v-icon right>
            arrow_forward
          </v-icon>
      </v-btn>
      </div>
    </template>

    <!-- Create Campaign Form -->
    <create-campaign-form
      v-if="shouldShowFormDialog"
      @close="shouldShowFormDialog = false"
      @refresh="fetchItems"
    />

    <!-- Confirm delete dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      :persistent="isLoading"
      max-width="500"
    >
      <v-card v-if="selectedItem">
        <v-card-title>
          Confirm your action
        </v-card-title>

        <v-card-text>
          You are about to delete campaign <b>{{ selectedItem.name }}</b> <br />
          This cannot be undone!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            @click="shouldShowDeleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            :loading="isLoading"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// Import children components
const StatusChip = () => import(/* webpackChunkName: "status-chip" */ "@/blocks/common/StatusChip")
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerOutreachIndex",

  // Register children components
  components: {
    StatusChip,
    LottieAnimation
  },

  // Define local data variables
  data: () => ({
    // Whether or not the request is being made
    isLoading: false,

    // To handle the delete process
    selectedItem: null,
    shouldShowDeleteDialog: false,

    // whether to show the create campaign form or not
    shouldShowFormDialog: false,
    shouldShowDownloadModal: {},

    // To filter data in the table
    selectedFilter: "All",
    filterOptions: [
      "All",
      "Active",
      "Completed"
    ],

    // related to table view
    tableHeaders: [
      {
        text: "#",
        value: "index",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false
      }
    ],
  }),

  // Define readonly data variables
  computed: {
    /**
     * Get the data from vuex state
     *
     * @returns {Array}
     */
    tableItems() {
      return this.$store.getters["influencerOutreach/items"]
        .filter((item) => {
          // If we have to show all the items
          if (this.selectedFilter === "All") {
            // Filter all of them to be shown
            return true
          }
          // If the campaign is in progress, show it
          else if (this.selectedFilter === "Active" && item.status === constants.model.campaign.status.inProgress) {
            return true
          }
          // If the campaign has been marked as done
          else if (this.selectedFilter === "Completed" && item.status === constants.model.campaign.status.completed) {
            return true
          }
          // Otherwise, don't show this at all
          else {
            return false
          }
        })
        // Now for each of them
        .map((item, index) => ({
          ...item,
          index: index + 1
        }))
    },

    /**
     * Compute whether to show a dialog or menu based on the device in use
     */
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    }
  },

  // Define local method functions
  methods: {
    /**
     * Whenever the select value for status is changed
     */
    async fetchItems() {
      // hide the form
      this.shouldShowFormDialog = false

      // show a loader
      this.isLoading = true

      await this.$store.dispatch("influencerOutreach/fetchItems")

      this.isLoading = false
    },

    /**
     * Make network request to delete each selected row
     *
     * @returns {void}
     */
     async handleDelete() {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: `/api/influencer-outreach/${this.selectedItem.id}`,
          method: "DELETE"
        })

        // Sync the model items
        this.fetchItems()
      } catch (error) {
        logger({ type: "CampaignIndex Delete Error", error })

        this.$store.dispatch("toasts/add", { text: "An error occurred!" })
      } finally {
        // Once all requests have been made, remove the loader
        this.$store.dispatch("loaders/remove", loaderId)

        // Reset the local state
        this.isLoading = false
        this.shouldShowDeleteDialog = false
      }
    },
  },

  /**
   * As soon as the component is ready
   *
   * @returns {void}
   */
  created() {
    // Dispatch fetch method for InfluencerOutreach from API
    this.fetchItems()
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 10em
</style>
